<template>
  <div class="overlay">
    <!-- <div class="spinner-container">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only"></span>
      </div>
    </div> -->
    <div class="pa-3 loader-container">
      <img src="../assets/rapidr-loading.gif">
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
}

.loader-container {
  background-color: RGB(39, 39, 39, 0.5);
  border-radius: 5px;
  text-align: center;
}
</style>
