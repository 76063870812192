import Vue from "vue";
import VueRouter from "vue-router";
import Meta from "vue-meta";
import MyLogIn from "../views/patient/MyLogIn.vue";
Vue.use(VueRouter);

Vue.use(Meta);
const routes = [
  {
    path: "/login",
    name: "Login",
    component: MyLogIn,
  },
  {
    path: "/verifyMC",
    name: "Verify MC",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/VerifyMC.vue"),
  },
  {
    path: "/",
    meta: { requiresAuth: true },
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/patient/MyHome.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/patient/MyRegister.vue"),
  },

  {
    path: "/forgetpassword",
    name: "Forget Password",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/patient/MyForgetPassword.vue"
      ),
  },
  {
    path: "/resetpassword",
    name: "Reset Password",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/patient/MyResetPassword.vue"
      ),
  },
  {
    path: "/profile",
    name: "My Profile",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/patient/MyProfile.vue"),
  },
  
  {
    path: "/consultation/:action/:id?",
    meta: { requiresAuth: true },
    name: "Consultation",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/patient/MyConsultation.vue"
      ),
  },
  // DOCTOR ROUTES
  {
    path: "/doctor/login",
    name: "Doctor Login",
    component: () => import("../views/doctor/MyDoctorLogIn.vue"),
  },
  {
    path: "/doctor/medication",
    name: "Medication",
    component: () => import("../views/doctor/MyDoctorMedication.vue"),
  },
  {
    path: "/doctor/consultation/id/:consultationId",
    meta: { requiresAuth: true },
    name: "Doctor Consultation",
    component: () => import("../views/doctor/MyDoctorConsultation.vue"),
  },
  {
    path: "/doctor/consultation/history",
    meta: { requiresAuth: true },
    name: "Doctor Consultation History",
    component: () => import("../views/doctor/MyDoctorConsultationHistory.vue"),
  },
  {
    path: "/doctor/consultation/await",
    meta: { requiresAuth: true },
    name: "Doctor Consultation Pending",
    component: () => import("../views/doctor/MyDoctorAwaitingConsultation.vue"),
  },
  {
    path: "/doctor/medication/list",
    meta: { requiresAuth: true },
    name: "Doctor Medication List",
    component: () => import("../views/doctor/MyDoctorMedication.vue"),
  },
  {
    path: "/verifyEmail",
    name: "Verify Email",
    component: () => import("../views/patient/MyVerifyEmail.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
});
function handleEmailAction(to) {
  const mode = to.query.mode;
  switch (mode) {
    case "resetPassword":
      return "/resetPassword";
    case "verifyEmail":
      return "/verifyEmail";
  }
}

router.beforeEach((to, from, next) => {
  if (to.path == "/emailAction") {
    next({
      path: handleEmailAction(to),
      query: to.query,
    });
  } else {
    const store = require("../store").default;
    if (!store.getters.isAuthenticated) {
      //Try to get from localstorage
      store.dispatch("init");
    }

    // if not authenticated, check which login to re route to
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      const isDoctorRoute = to.path.split("/")[1] == "doctor";
      if (!store.getters.isAuthenticated) {
        if (isDoctorRoute) {
          next({
            path: "/doctor/login",
            query: {
              redirect: to.fullPath,
            },
          });
        } else {
          next({
            path: "/login",
            query: {
              redirect: to.fullPath,
            },
          });
        }

        //if authenticated
      } else {
        if (isDoctorRoute) {
          if (store.getters.isDoctor) {
            next();
          }
          // not doctor but trying to access doctor route
          else {
            next("/");
          }
        } else {
          // doctor trying to access patient site
          if (store.getters.isDoctor) {
            next("/doctor/consultation");
          } else {
            next();
          }
        }
      }
    } else {
      //Redirect to dashboard if user is already logged in and trying to access Login page
      if (
        store.getters.isAuthenticated &&
        (to.name === "Login" || to.name === "Doctor Login")
      ) {
        if (store.getters.isDoctor) {
          next("/doctor/consultation");
        } else {
          next("/");
        }
      } else {
        next();
      }
    }
  }
});
export default router;
