import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ky from "ky";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "./assets/global_style.scss";
import firebaseConfig from "./firebaseConfig.js";
import firebase from "firebase/compat/app";
import "./assets/dayjs";
import VueMeta from "vue-meta";

firebase.initializeApp(firebaseConfig);

const api = ky.create({
  prefixUrl: process.env.VUE_APP_PREFIX_URL,
  throwHttpErrors: false,
});

const apiZoom = ky.create({
  prefixUrl:
    "https://zoom.us/oauth/authorize?response_type=code&client_id=0EuVC_SSTjyR6M2ZBSGWsg&redirect_uri=https://localhost:8080",
  throwHttpErrors: false,
});

Vue.use(BootstrapVue);
Vue.use(VueMeta);
Vue.use(IconsPlugin);
Vue.config.productionTip = false;
Vue.prototype.$http = api;
Vue.prototype.$httpZoom = apiZoom;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
